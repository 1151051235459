/**
 * Get the correct url based on window location href
 * @param {string} dev - The development url
 * @param {string} prod - The production url
 * @returns {string} - The correct url
 */

interface Props {
    dev: string;
    prod: string;
}

const getCorrectUrl = ({ dev, prod }: Props) => {
    if (typeof window === 'undefined') {
        return dev;
    }

    const url = window.location.href;
    const prodPath = 'toolbox.bytbil.com';

    return url.includes(prodPath) ? prod : dev;
};

export default getCorrectUrl;
